<template>
    <div style="width: 100%; height: 100vh; overflow: hidden;"
         :style="{backgroundImage:`url(${require('@/assets/login-bg.png')})`}"> <!--  :style="bg" 加背景图片-->
        <h1 style="font-size: 50px; text-align: center;color:#d64f44;margin-top: 50px;margin-bottom: 20px;">
            登录
        </h1>
        <el-card class="box-card" style="width: 500px;height: 490px;margin: 0 auto;">
        <div style="width: 400px; margin: 0 auto">

<!--            <div style="display: flex;flex-direction: row;align-items: center;justify-content: flex-start;">-->
<!--                <img :src="require('@/assets/logo.png')" style="width: 60px;height: 80px;"/>-->
<!--                <h2>和信资产</h2>-->
<!--            </div>-->
            <div style="display: flex;flex-direction: row;align-items: center;justify-content: flex-start;">
                <div style="width: 60px;height: 50px;"></div>
                <h2> </h2>
            </div>


            <div style="font-size: 20px; text-align: center; padding: 10px 0;margin-bottom: 20px;">欢迎您使用话务中心</div>

            <el-form ref="form" :model="form" size="normal" :rules="rules">
                <el-form-item prop="user_num">
                    <el-input prefix-icon="el-icon-user-solid" v-model="form.user_num" placeholder="请输入账号"></el-input>
                </el-form-item>
                <el-form-item prop="user_password">
                    <el-input prefix-icon="el-icon-lock" v-model="form.user_password" show-password placeholder="请输入密码"></el-input>
                </el-form-item>

                <el-form-item prop="checkcode">
                    <div style="display: flex;justify-content: space-between;align-items: center;">
                        <el-input prefix-icon="el-icon-key" v-model="form.checkcode" style="width: 50%;" placeholder="请输入验证码"></el-input>

                        <div class="checkcode" style="cursor: pointer;" @click="refreshCode">

                                <el-image :src="checkcodeUrl" v-loading="checkcodeLoading" style="overflow: initial;width: 100px;height: 40px;">
                                    <template #error>
                                        <div slot="error" class="image-slot"
                                             style="display: flex;height: 40px;justify-content: center;">
                                            <i class="el-icon-picture-outline" style="font-size:28px;padding-top: 6px;"></i>
                                        </div>
                                    </template>
                                </el-image>

                        </div>
                    </div>
                </el-form-item>
                <el-form-item>
                    <el-button style="width: 100%" :loading="loading" type="primary" @click="login">登 录</el-button>
                </el-form-item>
            </el-form>
        </div>
        </el-card>
    </div>
</template>

<script>
    import request from "../../utils/request";

    export default {
        name: "Login",
        data() {
            return {
                rules: {
                    user_num: [
                        {required: true, message: '请输入用户账号', trigger: 'blur'},
                    ],
                    user_password: [
                        {required: true, message: '请输入密码', trigger: 'blur'},
                    ],
                    checkcode: [
                        {required: true, message: '请输入验证码', trigger: 'blur'},
                    ],
                },
                form:{},
                loading:false,
                timer:'',
                checkcodeUrl:'',
                checkcodeLoading:false,
                nowResquestNum:0,//当前验证码请求个数，防止频繁点击验证码
            }
        },

        methods: {
            login() {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        if (!this.form.checkcode) {
                            this.$message.error("请输入验证码")
                            return
                        }

                        this.loading=true;
                        request.post("/login", this.form).then(res => {
                            if (res.code === 200) {
                                this.$message({
                                    type: "success",
                                    message: "登录成功"
                                })
                                this.$router.push("/tasklist")  //登录成功之后进行页面的跳转，跳转到主页
                            } else {
                                this.refreshCode()
                                this.$message({
                                    type: "error",
                                    message: res.msg
                                })
                            }
                            this.loading=false;
                        }).catch(e=>{
                            console.log(e);
                            this.loading=false;
                            this.$message({
                                type:"error",
                                message:'服务器异常',
                            })
                        })



                    }
                })
            },
            refreshCode() {//刷新验证码
                this.checkcodeLoading=true
                if(!this.form.checkcode_id){
                    this.form.checkcode_id="empty"
                }
                this.nowResquestNum++
                if(this.nowResquestNum>1){
                    this.nowResquestNum--
                    return
                }
                request.post("/checkcode",this.form.checkcode_id,{responseType:'blob'}).then(res=>{
                    window.URL.revokeObjectURL(this.checkcodeUrl)

                    this.form.checkcode_id=res.headers.checkcode_id
                    this.checkcodeUrl = window.URL.createObjectURL(res.data)
                    this.checkcodeLoading=false
                    this.nowResquestNum--
                }).catch(e=>{
                    console.log(e);
                    this.$message({
                        type:"error",
                        message:'服务器异常',
                    })
                    this.checkcodeLoading=false
                    this.nowResquestNum--
                })



            }
        },
        created() {
            //let NodeRSA = require('node-rsa');
            sessionStorage.removeItem("user")
            let that=this
            document.onkeydown =function(e){

                if(e.code=='Enter'||e.code=='enter'){
                    that.login()
                }
            }
            that.refreshCode()
        },
    }
</script>

<style scoped>
    .checkcode{
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        -webkit-user-select:none;
        -moz-user-select:none;
    }


</style>
